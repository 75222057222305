/**
 * jQuery.browser.mobile (http://detectmobilebrowser.com/)
 *
 * jQuery.browser.mobile will be true if the browser is a mobile device
 *
 **/
(function (a) {
  (jQuery.browser = jQuery.browser || {}).mobile =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    );
})(navigator.userAgent || navigator.vendor || window.opera);

(function ($) {
  // Init #heroSlider before doc ready
  if ($("#heroSlider").length) {
    $.fn.cycle.defaults.autoSelector = "#heroSlider";
    var progress = $("#progress-circle"),
      slideshow = $("#heroSlider");
    slideshow.on("cycle-post-initialize", function (e, opts) {
      progress.animate({ "stroke-dashoffset": "0" }, opts.timeout, "linear");
    });
    slideshow.on("cycle-initialized cycle-before", function (e, opts) {
      progress.stop(true).css("stroke-dashoffset", 138.073);
    });
    slideshow.on("cycle-initialized cycle-after", function (e, opts) {
      if (!slideshow.is(".cycle-paused"))
        progress.animate({ "stroke-dashoffset": "0" }, opts.timeout, "linear");
    });
    slideshow.on("cycle-paused", function (e, opts) {
      progress.stop();
      $("#resume-cycle").css("display", "block");
      $("#pause-cycle").css("display", "none");
    });
    slideshow.on("cycle-resumed", function (e, opts, timeoutRemaining) {
      progress.animate(
        { "stroke-dashoffset": "0" },
        timeoutRemaining,
        "linear"
      );
      $("#resume-cycle").css("display", "none");
      $("#pause-cycle").css("display", "block");
    });

    $("#arrows").click(function () {
      if (slideshow.hasClass("cycle-paused")) {
        $("#heroSlider").cycle("resume");
      }
    });

    $("#heroSlider").cycle({
      timeout: 8000,
      speed: 1000,
      centerHorz: "true",
      fx: "scrollVertUp",
      slides: "> .slide",
      pager: ".cycle-pager",
      pagerTemplate: "<a href='#' class='slider-dot blank'></a>",
    });
  }

  $(function () {
    // doc ready
    // Defer vid loads - only add src if not mobile
    if (!$.browser.mobile) {
      $.fn.deferLoad = function () {
        var newsrc = $(this).attr("data-src");
        $(this).attr("src", newsrc);
      };
      if ($("source.vid-defer").length) {
        $("source.vid-defer").each(function () {
          $(this).deferLoad();
          this.parentNode.load();
          this.parentNode.play();
        });
      }
      if ($("iframe.vid-defer").length) {
        $("iframe.vid-defer").each(function () {
          $(this).deferLoad();
        });
      }
    }

    $(window).on("resize", function () {
      $("video").resizeVid();
    });

    $.fn.resizeVid = function () {
      if ($("video").length) {
        var a = $(".headerBg"),
          b = $("#heroSlider video"),
          c = b[0],
          d = c.videoHeight,
          e = c.videoWidth,
          f = a.height(),
          g = a.width();
        b.css(
          g / e > f / d
            ? {
                width: g + 2,
                height: "auto",
              }
            : {
                width: "auto",
                height: f + 2,
              }
        );
      }
    };
    $("video").resizeVid();

    // Main menu
    /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
    var didScroll,
      lastScrollTop = 0,
      delta = 5,
      navbarHeight = $(".main-nav").outerHeight();

    // on refresh or # in url
    if ($(this).scrollTop() > 500) {
      $(".main-nav").css("transform", "translateY(-" + navbarHeight + "px)");
    }

    $(window).scroll(function () {
      didScroll = true;
    });
    // for performance
    setInterval(function () {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 250);

    function hasScrolled() {
      var pos = $(this).scrollTop(); // position
      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - pos) <= delta) {
        return;
      }
      // If they scrolled down and are past the navbar
      if (pos > lastScrollTop && pos > navbarHeight) {
        // Scroll Down
        $(".main-nav").css("transform", "translateY(-" + navbarHeight + "px)");
      } else {
        // Scroll Up
        if (pos + $(window).height() < $(document).height()) {
          $(".main-nav").css("transform", "translateY(0px)");
          $(".main-nav").addClass("nav-bg");
        }
      }
      lastScrollTop = pos;
      if (pos < 100) {
        $(".main-nav").removeClass("nav-bg");
      }
    }

    $(".navbar-toggler").click(function (e) {
      e.stopPropagation();
      doMenu();
    });
    $(document.body).click(function () {
      if ($(".menu-active").length) {
        doMenu();
      }
    });
    $("#navToggle").click(function (e) {
      e.stopPropagation(); // stop event bubbling up to body and triggering close
    });
    function doMenu() {
      $("body").toggleClass("menu-active");
      $(".mobile-menu").toggleClass("show-menu");
      if ($(".dropdown-active").length) {
        $(".dropdown-active").removeClass("dropdown-active");
      }
    }
    // Submenus
    $("#menu-mobile-menu.navbar-nav .menu-item-has-children a").click(
      function () {
        $(this).siblings(".dropdown-menu").addClass("dropdown-active");
      }
    );
    $("#menu-mobile-menu .menu-back-btn a").click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).parents(".dropdown-menu").removeClass("dropdown-active");
    });

    $(".event-slides").slick({
      slidesToShow: 3,
      centerMode: true,
      centerPadding: 0,
      focusOnSelect: true,
      prevArrow: $("#prev-event"),
      nextArrow: $("#next-event"),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
          },
        },
      ],
    });

    //set up gallery slider to work with multiple galleries on page
    $(".gallery-slides").each(function () {
      $(this).slick({
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false,
        prevArrow: $(this).parent().find(".prev-control"),
        nextArrow: $(this).parent().find(".next-control"),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              arrows: false,
              variableWidth: true,
              centerMode: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              centerMode: true,
              centerPadding: "0",
              focusOnSelect: true,
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false,
            },
          },
        ],
      });

      $(this).slick("setPosition");

    });

    // $(".gallery-slides").slick("setPosition");

    // Showpass filtering - format date correctly
    $("#date").change(function () {
      var date = $(this).val();
      var starts_on__gte = moment(date).toISOString();
      $("#starts_on__gte").val(starts_on__gte);
    });
    // Showpass fix - remove empty GET values from the query string so the filters work, remove #date - we dont need it
    $(".filter-form").submit(function () {
      $(this)
        .find(":input")
        .filter(function () {
          return !this.value;
        })
        .attr("disabled", "disabled");
      $(this).find("#date").attr("disabled", "disabled");
    });
    // Un-disable form fields when page loads, in case they click back after submission or client side validation
    $(".filter-form").find(":input").prop("disabled", false);

    // add theme class of first slide on initial load
    var theme = $("#heroSlider .slide").attr("data-theme-class");
    $(".main-nav").addClass(theme);
    var logo = $("#heroSlider .slide").attr("data-logo");
    $(".main-nav").addClass(logo);

    //if Current Cast Members element exists, change background color to yellow
    var currentCastLabelExists = $('label[for="current-cast"]').length;

    if (currentCastLabelExists) {
      $('label[for="current-cast"]').addClass("red-bg");
    }

    // AJAX filtering
    if ($(".blog").length === 0) {
      console.log('this is the blog page');
      $(".cat-filter input[type=checkbox]").change(function () {
        var nonce = $(this).attr("data-nonce");
        var catId = "";
        $("label.red-bg").removeClass("red-bg");
        $("input[type=checkbox]:checked").each(function () {
          $(this).parent().addClass("red-bg");
          catId += [this.value] + ",";
        });
        if (catId == "") {
          // if all unchecked get parent cat id
          catId = $(".parent-cat").val();
        }
        $.ajax({
          type: "POST",
          url: ajax_object.ajax_url,
          data: {
            action: "myfilter",
            catId: catId,
            nonce: nonce,
          },
          beforeSend: function (xhr) {
            // add loader gif?
            $("#response").html("Loading...");
          },
          success: function (data) {
            $("#response").html(data); // insert data
            console.log(data);
          },
          fail: function (data) {
            console.log(data);
          },
        });
        return false;
      });
    }

    // AJAX filtering resources
    $(".blog .cat-filter input[type=checkbox]").on("change", function () {
      var nonce = $(this).attr("data-nonce");
      var catId = "";

      $(".blog .cat-filter label").removeClass("red-bg");
      $(this).parent("label").toggleClass("red-bg");
      catId = this.value;

      console.log(catId);

      /*$("input[type=checkbox].filter-active").each(function () {
        $(this).parent().addClass("yellow-bg");
        //catId += [this.value] + ",";
        catId = this.value;
      });*/

      if (catId == "") {
        // if all unchecked get parent cat id
        catId = $(".parent-cat").val();
      }

      if ($('label[for="cast-cat"').hasClass("red-bg") == true) {
        var data = $.ajax({
          type: "POST",
          url: ajax_object.ajax_url,
          data: {
            action: "myfilter_resources_everything",
            catId: catId,
            nonce: nonce,
          },
          beforeSend: function (xhr) {
            // add loader gif?
          },
          success: function (data) {
            $("#response").html(data); // insert data
          },
          fail: function (data) {
            console.log(data);
          },
        });
      } else {
        var data = $.ajax({
          type: "POST",
          url: ajax_object.ajax_url,
          data: {
            action: "myfilter_resources",
            catId: catId,
            nonce: nonce,
          },
          beforeSend: function (xhr) {
            // add loader gif?
          },
          success: function (data) {
            $("#response").html(data); // insert data
          },
          fail: function (data) {
            console.log(data);
          },
        });
      }

      // console.log(data);
      return false;
    });
  }); // end doc ready
  // Only show outline when tabbing for keyboard users instead of removing completely (accessiblity concern)
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key. Hint: it's tab.
      document.body.classList.add("user-is-tabbing");
      window.removeEventListener("keydown", handleFirstTab);
    }
  }
  window.addEventListener("keydown", handleFirstTab);

  //make pagiation anchor to #response container

  var paginationURL = $(".pagination-row a").attr("href");
  var anchoredPaginationURL = paginationURL + "#response";

  $(".pagination-row a").attr("href", anchoredPaginationURL);

  // TOC Animation
  var fixed_el = $(".sidebar-box");
  if (fixed_el.length > 0) {
    var last_scroll_top = 0;
    var fixed_el_height = fixed_el.outerHeight();
    var top_crossed_el = $(".blog-post-content .container");
    var top_crossed_el_top = top_crossed_el.offset().top;
    var bottom_crossed_el = $(".related-resources");
    var bottom_crossed_el_padding = parseInt(
      bottom_crossed_el.css("paddingTop").replace("px", "")
    );
    var header_offset = $("#navbar").height();
    var bottom_crossed_el_position =
      bottom_crossed_el.offset().top - bottom_crossed_el_padding * 2 + 2;

    $(window).on("scroll", function () {
      fixed_el_top = fixed_el.offset().top;
      window_top = $(window).scrollTop();
      direction = $(this).scrollTop();

      if (direction < last_scroll_top) {
        // scroll up
        if (fixed_el.hasClass("freeze-toc")) {
          if (fixed_el_top > window_top + header_offset) {
            fixed_el.removeClass("freeze-toc");
          }
        }
        if (fixed_el.hasClass("scroll-toc")) {
          if (fixed_el_top < top_crossed_el_top) {
            fixed_el.removeClass("scroll-toc");
          }
        }
      } else {
        // scroll down
        if (fixed_el_top < window_top + header_offset) {
          fixed_el.addClass("scroll-toc");
        }

        // when the fixed el is at the bottom, freeze it
        if (fixed_el_top > bottom_crossed_el_position) {
          fixed_el.addClass("freeze-toc");
        }
      }

      last_scroll_top = direction;
    });
  }
})(jQuery);

var scroll_pos = 0;
var last_scroll_pos = 0;
var ticking = false;

function getDirection(scroll_pos, last_scroll_pos) {
  if (scroll_pos > last_scroll_pos) {
    return "down";
  } else if (scroll_pos < last_scroll_pos) {
    return "up";
  }
}

window.addEventListener("scroll", function (e) {
  scroll_pos = window.scrollY;
  if (!ticking) {
    window.requestAnimationFrame(function () {
      direction = getDirection(scroll_pos, last_scroll_pos);

      ticking = false;
      last_scroll_pos = scroll_pos;
    });

    ticking = true;
  }
});
